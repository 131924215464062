const apis = {
    getQuestionList: "/question/list",
    submitTestAnswer: "/answers/addAll",
    getAnalysisReport: "/statistics/statisticsScore",
    getLocationList: "/province/list",
    getAuthCode: "/api/v1/auth/authCode",
    login: "/api/v1/auth/login",

    //common
    getProvinceOrCityList:"/province/query",
    getDictionary:"/dictionaries/query",
    getSchoolLabelsById:"/school/label/querySchoolLabels",
    getSubjectList:"/subject/type/querySubjectType",
    getCourseList:"/subject/type/queryCourseType",
    getUserDetail:"/sys/user/detail",
    getServiceList:"/openservice/list",
    updateUserInfo:"/sys/user/更新用户",
    getDepartmentList:"/department/query",
    getTeacherList:"/teacher/list",
    postUserAdd:"/sys/user/add",
    deleteUser:"/sys/user/delete",
    //back
    backLogin:"/v1/auth/sys/login",
    backLoginByTel:"/v1/auth/sys/loginByPhone",
    backRegister:"/v1/auth/sys/register",
    getAuthCode:"/v1/auth/authCode",
    getSchoolByPage:"/school/list",
    getSchoolByPageCondition:"/school/queryList",
    getMajorByPage:"/subject/info/list",
    getMajorDetail:"/subject/info/detail",
    getBatchByPageCondition:"/batch/info/query",
    getHKTShcoolByPageCondition:"/sar/school/query",
    getStuderntByPage:"/student/list",
    getUserByPage:"/sys/user/list",
    getSchoolCourseYears:"/school/subject/schoolCourseYears",
    getScore:"/school/subject/queryScore",
    getSubjectSchool:"/school/subject/schoolCourse",
    getYears:"/school/subject/getYears",
    getSarSchool:"/sar/school/list",
    getCourse:"/course/list",
    getArchiveList:"/apply/student/list",
    createArchive:"/apply/student/add",
    createOrder:"/apply/order/createOrder",
    getOrderStatus:"/apply/order/getOrderInfoByOutTradeNo",
    getStudentDetail:"/apply/student/detail",
    getSchemeList:"/apply/recruiting/scheme/list",
    getStudentSchool:"/apply/recruiting/scheme/searchSchool",
    getStudentMajor:"/apply/major/searchMajors",
    getStudentAspirationList:"/apply/student/aspiration/list",
    getStudentAspirationGroupList:"/apply/student/aspiration/groupList",
    getStudentAspirationIdList:"/apply/student/aspiration/getStudentAspirationIds",
    addStudentAspirationList:"/apply/student/aspiration/addList",
    deleteAspiration:"/apply/student/aspiration/delete",
    aspirationUpdateSort:"/apply/student/aspiration/updateSort",
    updateSortByStudent:"/apply/student/aspiration/updateSortByStudent",
    getStudentProvience:"/apply/student/getStudentProvince",
    getSchoolNatureList:"/apply/recruiting/scheme/getSchoolNatureList",
    getStudentElectives:"/subject/combination/listByStudentId",
    updateStudentScore:"/apply/student/updateStudentScore",
    checkDiscountCode:"/sys/user/checkDiscountCode",
    getApplyStudentByCode:"/apply/student/getApplyStudentByCode",
    getApplyRecruitingGroup:"/apply/recruiting/group/list",
    getApplyRecruitingSchemeGroup:"/apply/recruiting/scheme/group/search",
    getSubjectMajorTypes:"/subjectType/info/getSubjectMajorTypes"
}
for (let attr in apis) {
    apis[attr] = apis[attr]
}
export default apis