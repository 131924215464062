<template>
  <div class="application-info-container">
    <div class="batch-search" id="printInfo">
      <div class="top-box">
        <div class="top-title">填报信息</div>
        <div class="archive-info-container" :header-cell-style="{ background: '#e6e6e6', color: '#646464' }"
          v-loading="archiveInfoLoading">
          <p v-if="!this.archiveInfoLoading">{{ this.yearTag }}年 {{ this.provinceNameTag }} {{ this.studentNameTag }}
            {{ this.subjectTag }} 分数区间{{ this.scoreRangeTag }}</p>
        </div>
      </div>

      <div class="table-container-body">
        <el-table :data="tableData" height="660" stripe class="table-body" ref="tableData" default-expand-all
          :header-cell-style="{ background: '#e6e6e6', color: '#646464' }" v-loading="tableLoading"
          @select="handleTableSelect" @select-all="handleTableSelectAll"
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
    >
          <el-table-column prop="id" fixed type="selection" width="42" align="center"
            v-model="province"></el-table-column>
          <el-table-column prop="province" label="省份" width="120" align="center" :show-overflow-tooltip="true">
            <template slot="header">
              <el-select class="form-control" v-model="province" placeholder="省份" multiple filterable
                @change="handleProvinceChange" v-loading="provinceListLoading"
                element-loading-spinner="el-icon-loading">
                <el-option v-for="item in provinceList" :key="item.key" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="city" label="城市" width="120" align="center" :show-overflow-tooltip="true">
            <template slot="header">
              <el-select class="form-control" placeholder="城市" multiple filterable :disabled="province == ''"
                @change="initTableData" v-model="city" v-loading="cityListLoading"
                element-loading-spinner="el-icon-loading">
                <el-option v-for="item in cityList" :key="item.key" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="school" label="院校" min-width="180" align="center" :show-overflow-tooltip="true">
            <template slot="header">
              <el-tooltip content="可输入空格可模糊查询多个院校(例:北京 上海)" placement="top">
                <el-autocomplete v-model="schoolName" placeholder="院校" :fetch-suggestions="querySchool"
                  :trigger-on-focus="false" @change="initTableData" @select="initTableData"
                  class="form-control"></el-autocomplete>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="major" label="专业" min-width="180" align="center" :show-overflow-tooltip="true">
            <template slot="header">
              <div class="major-select-container">
                <el-cascader v-model="selectedMajors" :options="majorCategories" :props="{
                  multiple: true,
                  // checkStrictly: true,
                  emitPath: false,
                  label: 'name',
                  value: 'id',
                  children: 'children'
                }" filterable placeholder="选择专业分类" clearable collapse-tags @change="handleMajorChange"
                  class="form-control"></el-cascader>
              </div>
            </template>
            <template slot-scope="scope">
              <el-button type="text" @click="handleMajorClick(scope.row)">{{ scope.row.major }}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="group_code" label="专业组代码" width="120" align="center" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="requireSub" label="选科要求" width="140" align="center" :show-overflow-tooltip="true">
            <template slot="header">
              <el-select v-model="requireSub" placeholder="选科要求" multiple @change="initTableData" class="form-control"
                clearable v-loading="requireSubListLoading" element-loading-spinner="el-icon-loading">
                <el-option v-for="item in requireSubList" :key="item.key" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="level" label="院校层次" width="130" align="center" :show-overflow-tooltip="true">
            <template slot="header">
              <el-select v-model="level" placeholder="院校层次" @change="initTableData" class="form-control" clearable
                v-loading="levelListLoading" element-loading-spinner="el-icon-loading">
                <el-option v-for="item in levelList" :key="item.key" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="tuition" label="学制/学费" width="130" align="center" :show-overflow-tooltip="true">
            <template slot="header">
              <el-input v-model="tuition" placeholder="学制/学费" @change="initTableData" class="form-control"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="compare" label="招生计划比" width="160" align="center" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="segment" label="2022年录取分" width="160" align="center" :show-overflow-tooltip="true">
            <template slot="header">
              <el-tooltip content="可输入'-'查询分数区间(例:500-600)" placement="top">
                <el-input v-model="segment" placeholder="去年录取分" @change="initTableData" class="form-control"></el-input>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="forecast" label="参考分" width="140" align="center" :show-overflow-tooltip="true">
            <template slot="header">
              <el-tooltip content="可输入'-'查询分数区间(例:500-600)" placement="top">
                <el-input v-model="forecast" placeholder="参考分" @change="initTableData" class="form-control"></el-input>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" min-width="140" align="center"
            :show-overflow-tooltip="true"></el-table-column>
        </el-table>
      </div>
      <div class="table-container-footer">
        <el-pagination layout="total, prev, pager, next, sizes, jumper" :page-sizes="[10, 15, 50, 100, 500]"
          :page-size="pageSize" :total="tableTotal" :current-page="pageNo" @current-change="pageChange"
          @size-change="sizeChange">
        </el-pagination>
        <div class="btn-box">
          <div class="btn" v-show="needBack" @click="handleBackClick">返回表格</div>
          <div class="btn" v-show="!needBack" @click="handleSelectedClick">已选({{ this.selected }})</div>
          <div class="btn" v-show="!needBack" @click="handleUnselectClick">未选({{ this.unselect }})</div>
          <div class="btn" @click="handleSaveClick">保存</div>
          <div class="btn" @click="handleMyApplicationClick">我的志愿</div>
        </div>
      </div>
    </div>
    <major-dialog :visible.sync="dialogVisible" @confirm="handleMajorConfirm" :school-data="currentSchool" :student-id="studentId" :isChecked="this.tableType === 'selected' ? true : (this.tableType === 'unselect' ? false : null)"/>
  </div>
</template>
<script>
import MajorDialog from './MajorDialog.vue'

export default {
  name: "batch",
  components: {
    MajorDialog
  },
  props: {
    msg: String,
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 15,
      tableTotal: 0,
      tableData: [],
      selected: 0,
      unselect: 0,
      tableLoading: false,
      provinceListLoading: false,
      cityListLoading: false,
      archiveInfoLoading: false,
      requireSubListLoading: false,
      levelListLoading: false,
      needBack: false,
      needInitAleradySelected: true,
      yearTag: "",
      provinceNameTag: "",
      studentNameTag: "",
      scoreRangeTag: "",
      subjectTag: "",
      studentId: 0,
      province: [],
      provinceList: [],
      city: [],
      cityList: [],
      level: "",
      levelList: [],
      requireSub: [],
      requireSubList: [],
      schoolName: "",
      majorName: "",
      tuition: "",
      compare: "",
      segment: "",
      forecast: "",
      tempSelectList: [],
      tableType: "",
      dialogVisible: false,
      currentSchool: null,
      selectedMajors: [],
      majorCategories: [],
      majorSelectList:[]
    };
  },
  methods: {
    initSelectData() {
      this.provinceListLoading = true;
      //获取省份
      this.$http.post(this.$apis.getProvinceOrCityList + "?type=province").then((res) => {
        this.provinceListLoading = false;
        if (res.data) {
          this.$store.commit("setProvinceList", res.data);
          this.provinceList = res.data.map(r => {
            return {
              key: String(r.id),
              label: r.name,
              value: String(r.id)
            }
          });
        }
      });

      this.levelListLoading = true;
      this.$http.post(this.$apis.getSchoolNatureList).then((res) => {
        this.levelListLoading = false;
        if (res.data) {
          this.levelList = res.data.map(r => {
            return {
              key: r.id,
              label: r.name,
              value: r.name
            }
          });
        }
      });

      this.requireSubListLoading = true;
      this.$http.post(this.$apis.getStudentElectives + "?studentId=" + this.studentId).then((res) => {
        this.requireSubListLoading = false;
        if (res.data) {
          this.requireSubList = res.data.map(r => {
            return {
              key: r,
              label: r,
              value: r
            }
          });
        }
      });
    },

    initCitySelectData() {
      this.cityListLoading = true;
      if (this.province && this.province != "") {
        //获取城市
        this.$http.post(this.$apis.getProvinceOrCityList + "?parentId=" + this.province).then((res) => {
          this.cityListLoading = false;
          if (res.data) {
            this.$store.commit("setCityList", res.data);
            this.cityList = res.data.map(r => {
              return {
                key: String(r.id),
                label: r.name,
                value: String(r.id)
              }
            });
          }
        });
      } else {
        this.cityListLoading = false;
      }
    },
    handleMajorConfirm(tableData) {
      if (tableData.filter(item => item.isChecked).length > 0) {
        debugger
        var contains = this.tempSelectList.some(value => value.id == this.currentSchool.row.id);
        if (!contains) {
          this.tempSelectList.push(this.currentSchool.row);
        }
        this.$refs.tableData.toggleRowSelection(this.currentSchool.row, true);
        this.calSelecttion();
      }
      const tempList = this.majorSelectList;
      tableData.forEach(item => {
          if (item.isChecked) {
            if (!tempList.some(row => row.id === item.id)) {
              this.majorSelectList.push(item);
            }
          } else {
            const index = tempList.findIndex(row => row.id === item.id);
            if (index !== -1) {
              this.majorSelectList.splice(index, 1);
            }
          }
        });
    },

    initArchiveInfo() {
      this.archiveInfoLoading = true;
      if (!this.studentId || this.studentId == 0) {
        this.$message({
          message: "学生信息有误!请重试!",
          type: "error",
        });
        this.$router.go(-1);
      }
      this.$http.post(this.$apis.getStudentDetail + "?id=" + this.studentId).then((res) => {
        this.archiveInfoLoading = false;
        if (res.data) {
          this.yearTag = res.data.year;
          this.provinceNameTag = res.data.provinceName;
          this.studentNameTag = res.data.name;
          this.subjectTag = res.data.course1 + res.data.course2 + res.data.course3;
          this.scoreRangeTag = (res.data.score - 50) + "-" + (res.data.score + 50);
        }
      });
    },

    querySchool(queryString, cb) {
      var index = queryString.lastIndexOf(",");
      var str = queryString.substring(index + 1, queryString.length);
      this.$http.post(this.$apis.getStudentSchool + "?studentId=" + this.studentId + "&schoolName=" + str).then((res) => {
        if (res.data) {
          var result = res.data.map(r => {
            return { value: r }
          });
          cb(result);
        }
      });
    },

    queryMajor(queryString, cb) {
      var index = queryString.lastIndexOf(" ");
      var str = queryString.substring(index + 1, queryString.length);
      this.$http.post(this.$apis.getStudentMajor + "?majorName=" + str).then((res) => {
        if (res.data) {
          var result = res.data.map(r => {
            return { value: r }
          });
          cb(result);
        }
      });
    },
    initTableData() {
      this.tableLoading = true;
      var preYearScoreMin = null,
        preYearScoreMax = null,
        estimatedScoreMin = null,
        estimatedScoreMax = null;
      if (this.segment.indexOf('-') > 0) {
        var scoreTempList = this.segment.split('-');
        preYearScoreMin = scoreTempList[0] == "" ? null : scoreTempList[0];
        preYearScoreMax = scoreTempList[1] == "" ? null : scoreTempList[1];
      } else {
        var tempScore = this.segment.split('-')[0];
        preYearScoreMin = preYearScoreMax = tempScore == "" ? null : tempScore;
      }

      if (this.forecast.indexOf('-') > 0) {
        var scoreTempList = this.forecast.split('-');
        estimatedScoreMin = scoreTempList[0] == "" ? null : scoreTempList[0];
        estimatedScoreMax = scoreTempList[1] == "" ? null : scoreTempList[1];
      } else {
        var tempScore = this.forecast.split('-')[0];
        estimatedScoreMin = estimatedScoreMax = tempScore == "" ? null : tempScore;
      }
      var data = {
        provinceIds: this.province,
        cityIds: this.city,
        school: this.schoolName,
        majorNameIds: this.selectedMajors,
        currentPage: this.pageNo,
        pageSize: this.pageSize,
        studentId: this.studentId,
        tuition: this.tuition,
        planRate: this.compare,
        courseLimit: this.requireSub,
        schoolNature: this.level,
        scoreMax: estimatedScoreMax,
        scoreMin: estimatedScoreMin,
        preYearScoreMin: preYearScoreMin,
        preYearScoreMax: preYearScoreMax
      };
      if (this.tableType === "selected") {
        data.isChecked = 1;
        data.ids = this.majorSelectList.map(r => { return r.id });
      } else if (this.tableType === "unselect") {
        data.isNotChecked = 1;
        data.ids = this.majorSelectList.map(r => { return r.id});
      }
      this.$http.post(this.$apis.getApplyRecruitingGroup, data).then((res) => {
        this.tableLoading = false;
        if (res.data && res.code === 200) {
          this.tableData = res.data.list.map(r => {
            return {
              id: r.id,
              province: r.schoolProvinceName,
              city: r.schoolCityName,
              school: r.schoolName,
              major: r.groupName,
              requireSub: r.course,
              tuition: r.schoolYearMin + "/" + r.tuitionMin,
              compare: r.planRate,
              segment: r.groupLowestScore,
              forecast: r.groupReferenceScore,
              remark: r.note,
              level: r.schoolNature,
              group_code: r.groupCode
            }
          });
          this.tableData.forEach(async item => {
              const res =  await this.$http.get(this.$apis.getApplyRecruitingSchemeGroup, 
              {groupCode: item.group_code, studentId: this.studentId, isChecked: this.tableType === "selected" ? true : null})
              item.major = item.major + "(" + res.data.length + ")"
          })
          this.selected = this.tempSelectList.length;
          this.unselect = this.tableData.length - this.selected < 0 ? 0 : this.tableData.length - this.selected;
          this.tableTotal = res.data.total;
          this.toggleSelection(this.tableData);
          this.calSelecttion();
        } else {
          this.$message.error(res.data.message || '获取数据失败');
        }
        if (this.needInitAleradySelected) {
        this.$http.post(this.$apis.getStudentAspirationGroupList + "?studentId=" + this.studentId).then((idRes) => {
          if (idRes && idRes.data) {
            var alreadySelectList = idRes.data.map(r => {
              return {
                id: r.id,
                applyRecruitingSchemeGroups: r.applyRecruitingSchemeGroups,
                province: r.schoolProvinceName,
                city: r.schoolCityName,
                school: r.schoolName,
                major: r.majorName,
                tuition: r.schoolYear + "/" + r.tuition,
                level: r.schoolNature,
                compare: r.planRate,
                segment: r.preYearScore + "/" + r.preYearScoreRanking,
                forecast: r.estimatedScore + "/" + r.estimatedRanking
              }
            });
            alreadySelectList.forEach(r => {
              let item = r.applyRecruitingSchemeGroups.map(r => {
                return {
                  id: r.id,
                }
              });
              this.majorSelectList.push(...item);
            });
            const tempList = this.tempSelectList;
            alreadySelectList.forEach(item => {
              var contains = tempList.some(value => value.id == item.id);
              if (!contains) {
                this.tempSelectList.push(item);
              }
            });
            this.toggleSelection(this.tableData);
            this.calSelecttion();
          }
        });
      }
      }).catch(err => {
        this.tableLoading = false;
        this.$message.error('获取数据失败');
        console.error('获取数据失败:', err);
      });
    },
    handleDelSelectClick() {
      this.$refs.tableData.clearSelection();
    },
    handleProvinceChange() {
      this.initCitySelectData();
      this.city = "";
      this.initTableData();
    },
    handleSearchClick() {
      this.pageNo = 1;
      this.initTableData();
    },
    handleBackClick() {
      this.needBack = false;
      this.tableType = "";
      this.handleSearchClick();
    },
    handleSaveClick() {
      var data = {
        majorIds: this.majorSelectList.map(r => { return r.id }),
        studentId: this.studentId
      };
      return this.$http.post(this.$apis.addStudentAspirationList, data).then((res) => {
        if (res.code == 200 && res.message === "SUCCESS") {
          this.$message({
            message: "添加成功!",
            type: "success",
          });
        } else {
          this.$message({
            message: "添加失败!原因:" + res.message,
            type: "error",
          });
        }
      });
    },
    async handleSelectedClick() {
      this.needBack = true;
      this.tableType = "selected"
      await this.handleSaveClick();
      this.initTableData();
    },
    async handleUnselectClick() {
      this.needBack = true;
      this.tableType = "unselect"
      await this.handleSaveClick();
      this.initTableData();
    },
    handleMyApplicationClick() {
      this.$router.push({
        name: "myApplicationOther",
        params: {
          studentId: this.studentId
        }
      });
    },
    handleTableSelect(selectList, row) {
      let selected = selectList.length && selectList.indexOf(row) !== -1;
      if (selected) {
        const tempList = this.tempSelectList;
        selectList.forEach(item => {
          var contains = tempList.some(value => value.id == item.id);
          if (!contains) {
            this.tempSelectList.push(item);
          }
        });
      }
      else if (selected == 0) {
        this.tempSelectList = this.tempSelectList.filter(r => {
          if (r.id !== row.id) {
            return r;
          }
        });
      }
      this.calSelecttion();
    },
    handleTableSelectAll(selectList) {
      const tempList = this.tempSelectList;
      if (selectList.length > 0) {
        selectList.forEach(item => {
          var contains = tempList.some(value => value.id == item.id);
          if (!contains) {
            this.tempSelectList.push(item);
          }
        });
      } else {
        this.tableData.forEach(item => {
          this.tempSelectList = this.tempSelectList.filter(r => {
            if (r.id !== item.id) {
              return r;
            }
          });
        });
      }
      this.calSelecttion();
    },
    calSelecttion() {
      this.selected = this.tempSelectList.length;
      this.unselect = this.tableTotal - this.selected < 0 ? 0 : this.tableTotal - this.selected;
    },
    pageChange(val) {
      this.pageNo = val;
      this.initTableData();
      this.$nextTick(() => {
        this.$refs.tableData.bodyWrapper.scrollTop = 0;
      })
    },
    sizeChange(val) {
      this.pageSize = val;
      this.initTableData();
    },
    toggleSelection(rows) {
      rows.forEach(row => {
        row.isSelect = this.tempSelectList.some(value => value.id == row.id);
        this.$refs.tableData.toggleRowSelection(row, row.isSelect);
      })
    },
    handleMajorClick(row) {
      this.currentSchool = {
        row:row,
        majorList:this.majorSelectList,
        group_code: row.group_code // 确保传递group_code到MajorDialog
      }
      this.dialogVisible = true
    },
    handleMajorChange(value) {
      this.selectedMajors = value;
      console.log('Selected majors:', value);
      this.initTableData();
    },
    getSubjectMajorTypes() {
      this.$http.get(this.$apis.getSubjectMajorTypes).then((res) => {
        if (res.code == 200 && res.message === "SUCCESS") {
          this.majorCategories = res.data
        } else {
          this.$message({
            message: "获取专业分类失败",
            type: "error",
          });
        }
      });
    }
  },
  mounted() {
    this.studentId = this.$route.params.studentId;
    this.initArchiveInfo();
    this.initSelectData();
    this.initTableData();
    this.getSubjectMajorTypes();
  },
};
</script>
<style lang="less" scoped>
.application-info-container {
  width: 100%;
  height: 100%;
}

.batch-search {
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  border-radius: 5px;
  border: 1px solid rgba(230, 230, 230, 100);

  .top-box {
    display: flex;
    align-items: center;
    padding: 13px 10px;
    border-bottom: 1px solid rgba(230, 230, 230, 100);

    .archive-info-container {
      padding-left: 400px;

      p {
        text-align: center;
        font-size: 24px;
      }
    }

    .top-title {
      color: rgba(16, 16, 16, 100);
      font-size: 20px;
      font-weight: 700;
      padding-left: 20px;
      position: relative;
    }

    .top-title::after {
      position: absolute;
      display: block;
      content: "";
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      height: 27px;
      width: 3px;
      background-color: #ff450b;
    }

    .btn-box {
      display: flex;
      align-items: center;

      .btn {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }

  .form-group {
    padding: 0px 20px;

    .form-item {
      display: flex;
      align-items: center;
      padding: 15px 0;

      .label {
        display: flex;
        justify-content: flex-end;
        color: rgba(100, 100, 100, 100);
        font-size: 14px;
        width: 70px;
        padding-right: 10px;
      }

      .input-box {
        display: flex;
        width: calc(100% - 100px);
      }

      .required-label {
        color: rgb(255, 0, 0);
      }

      .action-link {
        font-size: 16px;
        cursor: pointer;
        color: #ff450b;
        padding-right: 20px;
      }
    }
  }

  .form-control {
    width: 100%;
  }

  .table-container-body {
    .table-body {
      width: 100%;
      overflow: auto;
    }

    .table-btn-box {
      display: flex;
      align-items: center;
      justify-content: space-around;

      .table-btn {
        cursor: pointer;
        width: 60px;
        line-height: 28px;
        border-radius: 14px;
        color: rgba(121, 160, 241, 100);
        font-size: 12px;
        text-align: center;
        border: 1px solid rgba(121, 160, 241, 100);
      }
    }
  }

  .table-container-footer {
    padding: 5px 15px;
    display: flex;
    justify-content: space-between;

    .btn-box {
      padding: 0px 20px;
      display: flex;
      align-items: center;

      .btn {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }
}
</style>